.field-gradient {
    background: linear-gradient(0deg, #055719, #44a058, #85e99b, #00D4FF);
    background-image: url(../static/gr/brokemon/battleBases.png), linear-gradient(0deg, #055719, #44a058, #85e99b, #00D4FF);
    background-size:  120%;
    background-position: center;
    background-repeat: no-repeat;
}

#brokeInfoBody p{
    text-align: left;
} 

#battleTerminal {
    background: linear-gradient(0deg, #4d4e4d, #e6ece8, #e6ece8, #424546);
    border-top: 4px solid darkgray;
}

.battleHUD{
    background-size:  contain;
    background-repeat: no-repeat;
    height:80px;
    font-weight: bold;
    font-size: 1.3rem;
}

#playerHUD{
    background-image: url(../static/gr/brokemon/playerHUD.svg);
}

#cpuHUD{
    background-image: url(../static/gr/brokemon/cpuHUD.svg);
}

.hpRed{
    width: 50%;
    border-radius:8px;
    background-color:red;
    border:3px solid gray;
    outline:3px solid rgb(94, 92, 92);
}

.hpGreen{
    
    background-color:rgb(31, 238, 31);
}

.battle-message{
    border-radius: 5px;
    background-color:#F7EEDD;
    border: 3px solid lightseagreen;
    outline: 3px solid rgb(53, 53, 49);
    font-size: 1.8rem;
    font-weight: bold;
    font-family:'Courier New', Courier, monospace;
    text-shadow: 2px 2px 2px lightgray;
}

.pokeGif{
    max-height: 20vh;  
}

.brokeContent{
    background-color:#EEF5FF;
    font-size: 1.2rem;
    padding-bottom:10px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, .5);
}

#battleSpriteBack{
    height: 25svh;
}

#battleSpriteFront{
    height: 35svh;    
}

.battle-button{
    cursor:pointer;
    border-radius:5px;
    border: 3px solid rgba(0, 0, 0, .3);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom:5px;
    font-family:'Courier New', Courier, monospace;
    font-weight:bold;
    font-size: 1.8rem;
    text-shadow:1px 1px 1px #000;
    outline: 1px solid white;
    box-shadow:3px 3px 2px 0px black;
}

.battle-button:hover{
    box-shadow:1px 1px 1px black;
    outline: 1px solid lightgrey;
    color:lightgrey;
}

.typeBadge{
    border-radius: 3px;
    padding: 2px;
    font-weight:bold;
    text-shadow:1px 1px 1px #000;
}
  
.PoisonBadge{
    background-color: #AA6BC8;
    color: white;
}
  
.DarkBadge{
    background-color: #5A5465;
    color: white;
}
  
.GrassBadge{
    background-color: #63BC5A;
    color: white;
}
  
.SteelBadge{
    background-color: #5A8EA2;
    color: white;
}
  
.BugBadge{
    background-color: #91C12F;
    color: white;
}
  
.FightingBadge{
    background-color: #CE416B;
    color: white;
}
  
.NormalBadge{
    background-color: #929DA3;
    color: white;
}
  
.WaterBadge{
    background-color: #5090D6;
    color: white;
}
  
.RockBadge{
    background-color: #C5B78C;
    color: white;
}
  
.FireBadge{
    background-color: #FF9D55;
    color: white;
}
  
.GroundBadge{
    background-color: #D97845;
    color: white;
}
  
.IceBadge{
    background-color: #73CEC0;
    color: white;
}
  
.FlyingBadge{
    background-color: #8FA9DE;
    color: white;
}
  
.ElectricBadge{
    background-color: #F4D23C;
    color: white;
}
  
.PsychicBadge{
    background-color: #FA7179;
    color: white;
}
  
.FairyBadge{
    background-color: #EC8FE6;
    color: white;  
}
  
.GhostBadge{
    background-color: #5269AD;
    color: white;
}
  
.DragonBadge{
    background-color: #0B6DC3;
    color: white;
}