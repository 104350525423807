body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App-link {
  color: #61dafb;
}

header{
  background-color: #EEF5FF;
  font-size: 1.3rem;
}

.contentArea{
  background-color:#EEF5FF;
  font-size: 1.2rem;
 
  overflow-y:auto;
  padding-bottom:10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, .5);
}

#resumeFrame {
  min-height: 70vh;
}

#bcFrame{
  max-width:1084px;
  max-height:900px;
  height: 75vh;
  width:100%;
}

#rocketFrame, #canyonFrame, #expFrame{
  width:965px;
  height:584px;
  overflow:hidden;
}

a{
  font-weight: bold;
}

.stinkinBadge{
  cursor:pointer;
  box-shadow:2px 2px 3px #000; 
  background-color:rgba(205, 245, 253, .3);
}

.stinkinBadge:hover{
  cursor:pointer;
  box-shadow:1px 1px 1px #000; 
  background-color:rgba(105, 123, 125, .3);
}

.bgc{
  background-color:#86B6F6;
  padding:0px;
  background-image: url('./static/gr/waves.svg');
  background-blend-mode: multiply;
  background-size:100%;
}

.demoCard{
  cursor:pointer;
  box-shadow: 1px 1px 3px #000;
}